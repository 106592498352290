.App {
  height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
p {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
